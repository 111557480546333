<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md12>
        <v-card id="app_height" outlined>
          <v-layout pa-4 wrap justify-center>
            <v-flex text-center>
              <span style="font-size:16px;font-weight:600">User Details</span>
            </v-flex>
          </v-layout>
          <v-layout pa-5>
            <v-flex>
              <v-card>
                <table class="logtable" width="100%">
                  <tr>
                    <th>Username</th>
                    <td>{{userName}}</td>
                  </tr>
                  <tr v-if="userType=='Company'">
                    <th>Company Name</th>
                    <td>{{companyName}}</td>
                  </tr>
                  <tr>
                    <th>User Id</th>
                    <td>{{userId}}</td>
                  </tr>
                  <tr>
                    <th>UserType</th>
                    <td>{{userType}}</td>
                  </tr>
                  <tr>
                    <th>Email Id</th>
                    <td>{{mailId}}</td>
                  </tr>
                  <tr>
                    <th>Phone No</th>
                    <td>{{countryCode}} {{phoneNo}}</td>
                  </tr>
                  <tr v-if="userType=='Individual'">
                    <th>Firstname</th>
                    <td>{{firstName}}</td>
                  </tr>
                  <tr v-if="userType=='Individual'">
                    <th>LastName</th>
                    <td>{{lastName}}</td>
                  </tr>
                  <tr v-if="userType=='Individual'">
                    <th>Supporting Document</th>
                    <td>{{supDocName}}</td>
                  </tr>
                  <tr v-if="userType=='Individual'">
                    <th>Supporting Doc No</th>
                    <td>{{supDocNo}}</td>
                  </tr>
                  <tr v-if="userType=='Company'">
                    <th>Registration Country</th>
                    <td>{{registrationCountry}}</td>
                  </tr>
                  <tr v-if="userType=='Company'">
                    <th>Registration No</th>
                    <td>{{registrationNumber}}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>{{address}}</td>
                  </tr>
                  <tr>
                    <th>Address 2</th>
                    <td>{{address2}}</td>
                  </tr>
                  <tr>
                    <th>Address 2</th>
                    <td>{{address3}}</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>{{city}}</td>
                  </tr>
                  <tr>
                    <th>PostCode</th>
                    <td>{{postcode}}</td>
                  </tr>
                  <tr>
                    <th>Country</th>
                    <td>{{country}}</td>
                  </tr>
                  <tr>
                    <th>Phone Verified</th>
                    <td>{{isPhoneVerified}}</td>
                  </tr>
                  <tr>
                    <th>Email Verified</th>
                    <td>{{isEmailVerified}}</td>
                  </tr>
                  <tr>
                    <th>Account Created At</th>
                    <td>{{accountCreateAt}}</td>
                  </tr>
                  <tr>
                    <th>Last Action Time</th>
                    <td>{{lastactiontime}}</td>
                  </tr>
                </table>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout px-5 wrap justify-center>
            <v-flex>
              <h3 style="font-weight:600">Supporting Documents</h3>
            </v-flex>
          </v-layout>
          <v-layout px-5 pb-5 wrap justify-center>
            <v-flex>
              <ul v-for="(item,i) in documents" v-bind:key="i">
                <a :href="baseURL+'/u/'+item.filename" target="_blank">
                  <li>{{item.orginalname}} ({{item.type}})</li>
                </a>
              </ul>
              <br />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      showSnackBar: false,
      msg: null,
      timeout: 10000,
      appLoading: false,
      firstName: null,
      lastName: null,
      supDocNo: null,
      supDocName: null,
      address: null,
      address2: null,
      address3: null,
      city: null,
      postcode: null,
      country: null,
      userType: null,
      isPhoneVerified: false,
      isEmailVerified: false,
      userName: null,
      userId: null,
      mailId: null,
      phoneNo: null,
      countryCode: null,
      accountCreateAt: null,
      lastactiontime: null,
      registrationNumber: null,
      registrationCountry: null,
      companyName: null,
      documents: {}
    };
  },
  mounted() {
    this.appLoading = true;
    axios({
      method: "GET",
      url: "/user/info",
      headers: {
        "x-auth-token": localStorage.getItem("token")
      },
      params: {
        id: this.id
      }
    })
      .then(response => {
        this.appLoading = false;
        if (response.data.status) {
          var data = response.data.data;
          this.userType = data.type;
          if (this.userType == "Individual") {
            this.firstName = data.individual.firstName;
            this.lastName = data.individual.lastName;
            this.supDocNo = data.individual.supportingEvidence;
            this.supDocName = data.individual.supportingEvidenceName;
          }
          if (this.userType == "Company") {
            this.companyName = data.company.organisation;
            this.registrationCountry = data.company.registrationCountry;
            this.registrationNumber = data.company.registrationNumber;
          }
          this.address = data.address.address;
          this.userId = data._id;
          this.address2 = data.address.address2;
          this.city = data.address.city;
          this.postcode = data.address.postcode;
          this.country = data.address.country;
          this.address3 = data.address.address3;
          this.isPhoneVerified = data.isPhoneVerified;
          this.isEmailVerified = data.isEmailVerified;
          this.userName = data.username;
          this.mailId = data.email;
          this.phoneNo = data.phone;
          this.countryCode = data.phonePrefix;
          this.accountCreateAt = data.accountCreateAt;
          var dt = new Date(this.accountCreateAt);
          dt = dt.toString();
          dt = dt.slice(0, 25);
          this.accountCreateAt = dt;
          this.lastactiontime = data.lastactiontime;
          dt = new Date(this.lastactiontime);
          dt = dt.toString();
          dt = dt.slice(0, 25);
          this.lastactiontime = dt;
          this.documents = data.documents;
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      })
      .catch(err => {
        this.appLoading = false;
        this.msg = err.response.data.msg;
        this.showSnackBar = true;
      });
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}

.logtable,
.logtable th,
.logtable td {
  border: 1px solid black;
  border-collapse: collapse;
}
.logtable .logtable th,
.logtable td {
  padding: 5px;
  padding-left: 20px;
  text-align: left;
}
.logtable th {
  text-align: left;
  padding: 5px;
  padding-left: 50px;
  max-width: 80px;
}
</style>